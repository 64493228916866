import React from "react";
import "./About.scss";
import DevicesIcon from '@material-ui/icons/Devices';
import SpeedIcon from '@material-ui/icons/Speed';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import FlareIcon from '@material-ui/icons/Flare';

class About extends React.Component {
    render() {
        return(
            <section id="about" className="about">
                <h3>Sobre mí</h3>
                {/*  */}

                <div className="about-wrapper">
                    <div className="about-division">
                        <div className="about-circulo">
                            <div className="about-icons"> 
                                <SpeedIcon/>
                            </div>
                            <h5>Rápida</h5>
                            <p>Tiempos de carga rápidos e interacción sin retrasos.</p>
                        </div>

                        <div className="about-circulo">
                            <div className="about-icons"> 
                                <DevicesIcon/>
                            </div>
                            <h5>Responsive</h5>
                            <p>Diseños que funcionarán en cualquier dispositivo.</p>
                        </div>
                    </div>

                    <div className="about-division">
                        <div className="about-circulo">
                            <div className="about-icons"> 
                                <FlareIcon/>
                            </div>
                            <h5>Intuitiva</h5>
                            <p>Fuerte preferencia por UX / UI intuitivo y fácil de usar.</p>
                        </div>

                        <div className="about-circulo">
                            <div className="about-icons"> 
                                <FlightTakeoffIcon/>
                            </div>
                            <h5>Dinámica</h5>
                            <p>Me encanta hacer que los sitios web cobren vida.</p>
                        </div>
                    </div>
                </div>    

             
                <div className="about-container">

                    <div className="container-txt">
                        <h5>Un poco de información...</h5>
                        <p>Soy Full Stack Developer, especializado en el desarrollo de aplicaciones Web en diferentes entornos y aplicaciones para dispositivos móviles.</p>
                        <p>Profesionalmente conectado con el mundo del desarrollo web desde hace años. Siempre con ganas de seguir aprendiendo y descubriendo nuevos retos.</p>
                        <p>Experiencia en Bootstrap, SASS, Next.js, Redux, APIs, Gatsby, WPO, SPA, SCRUM, JIRA, Invision, GIT, Material UI, Styled components, Semantic UI...</p>
                    </div>
               

                    <div className="listado">
                        <h5>Skills</h5>
                        <ul className="tech-list">
                            <li>
                                {/* <div class="svg-item"> */}
                                    <svg viewBox="0 0 40 40">
                                        <circle className="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5"></circle>
                                        <circle className="donut-segment donut-segment-1" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5" strokeDasharray="90 10" strokeDashoffset="25"></circle>  
                                        <text y="50%" transform="translate(0, 2)"><tspan x="50%" textAnchor="middle" className="donut-percent">90%</tspan> </text>
                                    </svg>
                                
                                <p>Javascript</p>
                            </li>
                            <li>
                                
                                    <svg viewBox="0 0 40 40">
                                        <circle className="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5"></circle>
                                        <circle className="donut-segment donut-segment-2" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5" strokeDasharray="80 20" strokeDashoffset="25"></circle>
                                        <text y="50%" transform="translate(0, 2)"><tspan x="50%" textAnchor="middle" className="donut-percent">80%</tspan> </text>
  
                                    </svg>
                                
                                <p>ReactJS</p>
                            </li>
                            <li>
                                
                                    <svg viewBox="0 0 40 40">
                                        <circle className="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5"></circle>
                                        <circle className="donut-segment donut-segment-3" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5" strokeDasharray="80 20" strokeDashoffset="25"></circle>  
                                        <text y="50%" transform="translate(0, 2)"><tspan x="50%" textAnchor="middle" className="donut-percent">80%</tspan> </text>
                                    </svg>
                                
                                <p>Node Js</p>
                            </li>
                            <li>
                                
                                    <svg viewBox="0 0 40 40">
                                        <circle className="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5"></circle>
                                        <circle className="donut-segment donut-segment-4" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5" strokeDasharray="70 30" strokeDashoffset="25"></circle> 
                                        <text y="50%" transform="translate(0, 2)"><tspan x="50%" textAnchor="middle" className="donut-percent">70%</tspan> </text> 
                                    </svg>
                                
                                <p>MongoDB</p>
                            </li>
                            <li>
                                
                                    <svg viewBox="0 0 40 40">
                                        <circle className="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5"></circle>
                                        <circle className="donut-segment donut-segment-5" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5" strokeDasharray="70 30" strokeDashoffset="25"></circle> 
                                        <text y="50%" transform="translate(0, 2)"><tspan x="50%" textAnchor="middle" className="donut-percent">70%</tspan> </text> 
                                    </svg>
                                
                                <p>Express</p>
                            </li>
                            <li>
                                
                                    <svg viewBox="0 0 40 40">
                                        <circle className="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5"></circle>
                                        <circle className="donut-segment donut-segment-6" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5" strokeDasharray="90 10" strokeDashoffset="25"></circle>  
                                        <text y="50%" transform="translate(0, 2)"><tspan x="50%" textAnchor="middle" className="donut-percent">90%</tspan> </text>
                                    </svg>
                                
                                <p>CSS3</p>
                            </li>

                            <li>
                                
                                <svg viewBox="0 0 40 40">
                                    <circle className="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5"></circle>
                                    <circle className="donut-segment donut-segment-7" cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3.5" strokeDasharray="90 10" strokeDashoffset="25"></circle>  
                                    <text y="50%" transform="translate(0, 2)"><tspan x="50%" textAnchor="middle" className="donut-percent">90%</tspan> </text>
                                </svg>
                            
                            <p>HTML5</p>
                        </li>

                        </ul>
                    </div>


                </div> 
            </section>
        )
    }
}

export default About;