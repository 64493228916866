import React, { Component } from 'react';
import './App.css';
// import Nav from "./components/Nav";
import Main from "./components/Main";
import About from "./components/About";
import Proyects from "./components/Proyects";
import Contacto from "./components/Contacto";
import Footer from "./components/Footer";

var Spinner = require('react-spinkit');

class App extends Component {
  
  constructor(props) {
    super(props);
      this.state={
        cargando: true
      }

      this.change();
  }

  change = () => {
    setTimeout(() => {
      this.setState({
        cargando: false
      })
    }, 3000)
  }

  render(){
    if (this.state.cargando){
      return(
        <div className="loading">
          <Spinner name="cube-grid" color="white"/>
        </div>
      )
    } else {
      return (     
        <div>
        {/* <Nav/> */}
        <Main/>
        <About/>
        <Proyects/>
        <Contacto/>
        <Footer/>
        </div>
      )
    }
  }
}

export default App;
