import React from "react";
import "./Proyecto.scss";

class Proyecto extends React.Component {

    render() {
        return(
            <div className="proyecto">
                <div className="img-container">
                        
                    <img src={this.props.proyecto.img} alt={this.props.proyecto.name}/>                    

                    <div className="text-container">
                        <div>
                            <h5>{this.props.proyecto.name}</h5>
                            <h6>{this.props.proyecto.tech}</h6>
                            <p>"{this.props.proyecto.descripcion}"</p>
                        </div>
                        <a className="cta-btn cta-btn--hero" href={this.props.proyecto.url} target="_blank" rel="noopener noreferrer">Ver proyecto</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Proyecto;