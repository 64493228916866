import React from "react";
import "./Proyects.scss";
import proyectos from "../proyectos.json";
import Proyecto from "./Proyecto";

class Proyects extends React.Component {

    state = {
        proyectos: proyectos
    }
    
    render() {
        const { proyectos } = this.state;

        return(
            <section id="proyects" className="proyects">
                <h3>Proyectos</h3>
                <div className="proyects-container">
                    {proyectos.map((proyecto) => {
                        return (
                            <Proyecto 
                                key={proyecto.id} 
                                proyecto={proyecto} 
                            />
                        )
                    })}

                </div>
            </section>
        )
    }
}

export default Proyects;