import React from "react";
import "./Main.scss";


class Main extends React.Component {
    
    render() {
        return(
            <section className="main">
                <div className="main-wrapper">
                    <h1>Hola,</h1>
                    <h1>Soy <span>Pedro Ramos</span>.</h1>
                    <h2>Full-stack developer</h2>
                    <div className="botones">
                        <a className="cta-btn cta-btn--hero mar-right" href="#about">Conóceme</a>
                    </div>
                </div>
            </section>
        )
    }
}

export default Main;